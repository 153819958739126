import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import BurdCrumbs from "../../components/burdCrumbs";
import TableLayout from "../../components/table";
import Modal from "../../components/modal";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constants";
import axios from "axios";
import { toast } from "react-toastify";
import { Loader } from "rsuite";
import { Input } from "../../components/form-elements";
import { addition } from "../../assets/svgIcons";
import { userData } from "../../data/fake_login_data";
const Quizzes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [hiddenId, setHiddenId] = useState(false);
  const [quizzName, setQuizzName] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [editId, setEditId] = useState(false);
  const [opened, setOpened] = useState(null);
  const headers = [
    {
      label: "ID",
      dataIndex: "quiz_id",
      search: true,
      sort: true,
    },
    {
      label: "Actions",
      type: "children",
      children: ({ headers, row, index, lastIndex }) => {
        return (
          <div className="menu_Open_close">
            <div
              className="open_close"
              style={{ cursor: "pointer", color: "black" }}
              onClick={() =>
                setOpened(opened == row?.quiz_id ? null : row?.quiz_id)
              }
            >
              <img
                src="https://res.cloudinary.com/duovxefh6/image/upload/v1701489400/menu_hrpzcb.png"
                alt=""
                style={{ width: "20px", height: "20px" }}
              />
            </div>
            {row?.quiz_id == opened ? (
              <div
                className={
                  index == lastIndex - 1 ||
                  index == lastIndex - 2 ||
                  index == lastIndex - 3
                    ? "actions-views row-actions-view"
                    : "actions-views column-actions-view"
                }
              >
                {/* <button
                  className="btn btn-danger"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    setDeleteId(row);
                  }}
                >
                  Delete
                </button> */}
                <button
                  className="btn btn-primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    setEditId(row);
                  }}
                >
                  Edit
                </button>
                {/* setEditId */}
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      label: "quiz_photo",
      type: "children",
      children: ({ row }) => {
        return (
          <img
            src={row?.quiz_image}
            alt={row?.quiz_name}
            onClick={() =>
              navigate("QuizzezQuestion", {
                state: { ...location?.state, quiz: row },
              })
            }
          />
        );
      },
    },
    {
      label: "quizz_name",
      dataIndex: "quiz_name",
      search: true,
      sort: true,
    },
    {
      label: "quiz_time",
      dataIndex: "quiz_time",
      // search: true,
      sort: true,
    },
    {
      label: "quiz_end_date",
      dataIndex: "quiz_end_date",
      // search: true,
      sort: true,
    },
    {
      label: "Current status",
      type: "children",
      children: ({ row }) => {
        if (row?.show == 1) {
          return <span className="text-success textCurrent">Show</span>;
        } else {
          return <span className="text-danger textCurrent">Hide</span>;
        }
      },
    },
    {
      label: "Change status",
      type: "children",
      children: ({ row }) => {
        if (row.show == 1) {
          return (
            <span
              className="btn btn-success"
              style={{ color: "black" }}
              onClick={() => setHiddenId(row)}
            >
              Show
            </span>
          );
        } else {
          return (
            <span
              className="btn btn-danger"
              style={{ color: "black" }}
              onClick={() => setHiddenId(row)}
            >
              Hide
            </span>
          );
        }
      },
    },
    // setHiddenId
  ];
  window.addEventListener("click", (e) => {
    if (
      opened &&
      !e.target?.classList?.contains("actions-views") &&
      !e.target?.parentNode?.classList?.contains("open_close")
    ) {
      setOpened(null);
    }
  });
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        BASE_URL + "quiz/select_all_quizes.php",
        {
          course_id: location?.state?.course_id,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );
      setData(response?.data?.message);
    } catch (err) {
      setData([]);
      toast.error(err?.message);
    }
  };
  const addQuizz = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;

    try {
      e.preventDefault();
      setLoading(true);
      const form = e.target;
      const formData = new FormData(form);
      const imageUpload = await axios.post(
        BASE_URL + "item_img_uploader.php",
        formData
      );
      if (imageUpload?.data?.status == "success") {
        const response = await axios.post(BASE_URL + "quiz/insert_quizz.php", {
          course_id: location?.state?.course_id,
          quiz_name: e?.target?.quiz_title?.value,
          quiz_end_date: e?.target?.quiz_end_time?.value,
          quiz_time: e?.target?.quiz_time?.value,
          quiz_image: imageUpload?.data?.message,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        });
        if (response.data?.status == "success") {
          toast.success(response.data?.message);
          fetchData();
          setShowAdd(false);
          form.reset();
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(response.data?.message);
        }
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const handleDeleteQuizz = () => {
    const data_send = {
      quiz_id: deleteId?.quiz_id,
      admin_id: userData?.user_id,
      access_token: userData?.access_token,
    };
    axios
      .post(BASE_URL + "quizzes/delete_quiz.php", JSON.stringify(data_send))
      .then((res) => {
        if (res.data.status == 'success') {
          toast.success(res.data.message);
          setDeleteId(false);
          fetchData();
        } else if (res.data.status == 'error') {
          toast.error(res.data.message);
        } else {
          toast.error("Something Went Error");
        }
      });
  };
  const handleEditQuizz = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;

    try {
      e.preventDefault();
      setLoading(true);
      const formData = new FormData(form);
      const imageUpload = await axios.post(
        BASE_URL + "item_img_uploader.php",
        formData
      );
      if (
        imageUpload?.data?.status == "success" ||
        !e.target?.image?.files?.length
      ) {
        const response = await axios.post(BASE_URL + "quiz/update_quizz.php", {
          course_id: location?.state?.course_id,
          quiz_id: editId?.quiz_id,
          quiz_name: form?.quiz_title?.value,
          quiz_end_date: form?.quiz_end_time?.value,
          quiz_time: form?.quiz_time?.value
            ? form?.quiz_time?.value
            : editId?.quiz_time,
          quiz_image:
            imageUpload?.data?.status == "success" && form?.image?.files?.length
              ? imageUpload?.data?.message
              : editId?.quiz_image,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        });
        if (response.data?.status == "success") {
          toast.success(response.data?.message);
          fetchData();
          setEditId(false);
          form.reset();
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(response.data?.message);
        }
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const hideQuiz = async () => {
    setLoading(true);

    try {
      const response = await axios.post(BASE_URL + "quiz/show_hide_quiz.php", {
        quiz_id: hiddenId?.quiz_id,
        show_value: hiddenId?.show == 0 ? 1 : 0,
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      });
      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setHiddenId(false);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  if (!location?.state?.course_id) {
    navigate(-1);
  }
  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <h4 className="customBreadCrumbs">
              <span>Quizzes</span>
              <button
                className="btn btn-primary"
                onClick={() => setShowAdd(true)}
              >
                <span>{addition}</span>
                <span>Add Quiz</span>
              </button>
            </h4>
            <TableLayout
              searchHeaderKet={searchHeaderKet}
              headers={headers}
              data={data}
            />
          </div>
        }
      />
      <Modal
        headerTitle={"Add Quiz"}
        open={showAdd}
        toggle={setShowAdd}
        children={
          <form
            action="#"
            onSubmit={!loading ? addQuizz : (e) => e.preventDefault()}
          >
            <div className="inputField">
              <label htmlFor="quiz_title">Quiz Title</label>
              <Input
                type={"text"}
                id={"quiz_title"}
                name={"quiz_title"}
                onChange={() => null}
              />
            </div>
            <div className="inputField">
              <label htmlFor="quiz_end_time">Quiz End Date</label>
              <Input
                type={"date"}
                id={"quiz_end_time"}
                name={"quiz_end_time"}
                onChange={() => null}
              />
            </div>
            <div className="inputField">
              <label htmlFor="quiz_time">Quiz Time</label>
              <Input
                type={"time"}
                id={"quiz_time"}
                name={"quiz_time"}
                onChange={() => null}
              />
            </div>
            <div className="inputField">
              <label htmlFor="image">Image URL</label>
              <Input
                type={"file"}
                id={"image"}
                name={"image"}
                showImage={true}
                onChange={() => null}
              />
            </div>

            <button className="btn btn-success">
              {!loading ? "Save" : <Loader />}
            </button>
          </form>
        }
      />
      <Modal
        open={hiddenId}
        toggle={setHiddenId}
        headerTitle={"Show / Hide Quizz"}
        children={
          <>
            <h5>
              Are You Sure To {hiddenId?.show == 1 ? "Hide - " : "Show - "}
              {hiddenId?.quiz_name} ?
            </h5>
            <div className="flex-box">
              <button
                className="btn btn-danger"
                onClick={() => {
                  return !loading ? hideQuiz() : null;
                }}
              >
                {!loading ? "Yes" : <Loader />}
              </button>
              {console.log(hiddenId)}
              <button
                className="btn btn-primary"
                width={"fit-content"}
                onClick={() => {
                  setHiddenId(false);
                }}
              >
                No
              </button>
            </div>
          </>
        }
      />
      <Modal
        open={deleteId}
        toggle={setDeleteId}
        headerTitle={"Delete Quizz"}
        children={
          <>
            <h5>
              {"Are You Sure To Delete Quiz - " + deleteId?.quiz_name + " ?"}
            </h5>
            <div className="flex-box">
              <button
                className="btn btn-danger"
                onClick={() => {
                  hideQuiz();
                }}
              >
                Yes
              </button>

              <button
                className="btn btn-primary"
                width={"fit-content"}
                onClick={() => {
                  setDeleteId(false);
                }}
              >
                No
              </button>
            </div>
          </>
        }
      />

      <Modal
        headerTitle={"Edit Quizz"}
        open={editId}
        toggle={setEditId}
        children={
          <form
            action="#"
            onSubmit={!loading ? handleEditQuizz : (e) => e.preventDefault()}
          >
            <div className="inputField">
              <label htmlFor="quizz_name">Quizz Name</label>
              <Input
                type={"text"}
                id={"quizz_name"}
                name={"quiz_title"}
                defaultValue={editId?.quiz_name}
                onChange={(e) => null}
              />
            </div>
            <div className="inputField">
              <label htmlFor="quiz_end_time">Quiz End Date</label>
              <Input
                type={"date"}
                id={"quiz_end_time"}
                name={"quiz_end_time"}
                defaultValue={editId?.quiz_end_date}
                onChange={() => null}
              />
            </div>
            <div className="inputField">
              <label htmlFor="quiz_time">Quiz Time</label>
              <Input
                type={"time"}
                id={"quiz_time"}
                name={"quiz_time"}
                defaultValue={editId?.quiz_time}
                onChange={() => null}
              />
            </div>
            <div className="inputField">
              <label htmlFor="image">Image URL</label>
              <Input
                type={"file"}
                id={"image"}
                name={"image"}
                showImage={true}
                onChange={() => null}
              />
            </div>
            <button className="btn btn-success">
              {!loading ? "Save" : <Loader />}
            </button>
          </form>
        }
      />
    </div>
  );
};

export default Quizzes;
