import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import "./style.css";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constants";
import { Loader } from "rsuite";
import { addition } from "../../assets/svgIcons";
import { Input } from "../../components/form-elements";
import Modal from "../../components/modal";
import TextArea from "../../components/form-elements/textarea";
import { userData } from "../../data/fake_login_data"; 
import { perms } from "../../data/side_nav_data";
import { exportToCSV } from "../../functions";
const ChainsCourses = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchHeaderKet, setSearchHeaderKey] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [courseName, setCourseName] = useState(false);
  const [courseLogo, setCourseLogo] = useState(false);
  const [courseDescription, setCourseDescription] = useState(false);
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [editId, setEditId] = useState(false);
  const [filteredData, setFilteredData] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.post(
        BASE_URL + "courses/courses/select_course_chain.php",
        {
          chain_id: location?.state?.chain_id,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );
      setData(response?.data?.message);
    } catch (err) {
      setData([]);
      toast.error(err?.message);
    }
  };
  const addCourse = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    const formData = new FormData();
    formData.append("image", courseLogo);
    try {
      const imageUpload = await axios.post(
        BASE_URL + "item_img_uploader.php",
        formData
      );
      if (imageUpload?.data?.status == "success") {
        const response = await axios.post(
          BASE_URL + "courses/courses/insert_course.php",
          {
            chain_id: location?.state?.chain_id,
            course_name: courseName,
            course_photo: imageUpload?.data?.message,
            course_description: courseDescription,
            university_id: 1,
            admin_id: userData?.user_id,
            access_token: userData?.access_token,
          }
        );

        if (response.data?.status == "success") {
          toast.success(response.data?.message);
          fetchData();
          setShowAdd(false);
          setCourseLogo(null);
          setCourseName(null);
          form.reset();
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(response.data?.message);
        }
      } else {
        setCourseLogo(false);
        toast.error("Failed on Upload Image");
        setLoading(false);
      }
      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const editCourse = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    const formData = new FormData();
    formData.append("image", courseLogo);
    try {
      const imageUpload = await axios.post(
        BASE_URL + "item_img_uploader.php",
        formData
      );
      if (
        imageUpload?.data?.status == "success" ||
        !courseLogo ||
        editId?.course_photo
      ) {
        const response = await axios.post(
          BASE_URL + "courses/courses/update_course_new.php",
          {
            course_id: editId?.course_id,
            chain_id: location?.state?.chain_id,
            course_photo:
              imageUpload?.data?.message &&
              imageUpload?.data?.message?.length &&
              courseLogo
                ? imageUpload?.data?.message
                : editId?.course_photo,
            course_name: courseName ? courseName : editId?.course_name,
            course_description: courseDescription
              ? courseDescription
              : editId?.course_description,
            admin_id: userData?.user_id,
            access_token: userData?.access_token,
          }
        );
        if (response.data?.status == "success") {
          fetchData();
          setEditId(false);
          setCourseLogo(null);
          setCourseName(null);
          form.reset();
          setLoading(false);
          toast.success(response.data?.message);
        } else {
          setLoading(false);
          toast.error(response.data?.message);
        }
      } else {
        setCourseLogo(false);
        toast.error("Failed on Upload Image");
        setLoading(false);
      }
      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const handleDeleteCourse = () => {
    const data_send = {
      course_id: deleteId?.course_id,
      admin_id: userData?.user_id,
      access_token: userData?.access_token,
      hidden: deleteId?.hidden == "no" ? "yes" : "no",
    };
    axios
      .post(BASE_URL + "courses/courses/show_hide_course.php", JSON.stringify(data_send), {
        headers: {
          "Content-Type": "Application/Json",
        },
      })
      .then((res) => {
        if (res.data.status == 'success') {
          toast.success(res.data.massage);
          setDeleteId(false);
          fetchData();
        } else if (res.data.status == 'error') {
          toast.error(res.data.massage);
        } else {
          toast.error("Something Went Error");
        }
      });
  };
  useEffect(() => {
    if (searchHeaderKet && searchHeaderKet?.length) {
      setFilteredData(
        data?.filter(
          (item) =>
            item?.course_id == searchHeaderKet ||
            item?.course_name?.includes(searchHeaderKet) ||
            item?.course_description?.includes(searchHeaderKet)
        )
      );
    } else {
      setFilteredData(data);
    }
  }, [searchHeaderKet, data]); // Use a Set to keep track of unique items
  if (!location?.state?.chain_id) {
    navigate(-1);
  }

  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <>
            <h4 className="customBreadCrumbs">
              <span>Courses For Chain ({location.state?.chain_name})</span>
              {perms?.includes("*4_2") || perms?.startsWith("4_2") ? (
                <button
                  className="btn btn-primary"
                  onClick={() => setShowAdd(true)}
                >
                  <span>{addition}</span>
                  <span>Add Course</span>
                </button>
              ) : null}
            </h4>
            {(perms?.includes("*17_4") || perms?.startsWith("17_4")) &&
            data?.length ? (
              <button
                className="btn btn-primary exportBtn"
                onClick={() => {
                  exportToCSV(
                    data,
                    location?.state?.course_name + " - Courses Data"
                  );
                }}
              >
                Export
              </button>
            ) : null}
            <div className="filters_box">
              {/* <div className="orderby"> */}
              {/* <input type="text" id="orderBy" /> */}
              {/* </div> */}
            </div>
            {!data && !filteredData ? (
              <Loader />
            ) : data?.length && filteredData?.length ? (
              <div className="course_Container">
                {filteredData?.map((item) => {
                  return (
                    <div className="course">
                      <img
                        src={item?.course_photo}
                        alt=""
                        onClick={() =>
                          perms?.includes("*4_1") || perms?.startsWith("4_1")
                            ? navigate("chapters", {
                                state: {
                                  university_id: 1,
                                  grade_id: 1,
                                  course_id: item?.course_id,
                                  chain_id: location?.state?.chain_id,
                                  course_name: item?.course_name,
                                },
                              })
                            : null
                        }
                      />
                      <div className="topCourseHeader">
                        <div
                          className="left"
                          onClick={() =>
                            perms?.includes("*4_1") || perms?.startsWith("4_1")
                              ? navigate("chapters", {
                                  state: {
                                    university_id:
                                      location?.state?.university_id,
                                    grade_id: 1,
                                    course_id: item?.course_id,
                                    chain_id: location?.state?.chain_id,
                                    course_name: item?.course_name,
                                  },
                                })
                              : null
                          }
                        >
                          {item?.course_name}
                        </div>
                        <div className="right">
                          <span
                            onClick={() => {
                              setShowActions(
                                showActions !== item?.course_id
                                  ? item?.course_id
                                  : false
                              );
                            }}
                          >
                            ...
                          </span>
                          {item?.course_id == showActions ? (
                            <div className="courseActions">
                              {perms?.includes("*4_3") ||
                              perms?.startsWith("4_3") ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={() => setEditId(item)}
                                >
                                  Edit
                                </button>
                              ) : null}

                              <button
                                className="btn btn-primary"
                                onClick={() => setDeleteId(item)}
                              >
                                {item?.hidden == "no" ? "Hide" : "Show"}
                              </button>
                              {perms?.includes("*4_6") ||
                              perms?.startsWith("4_6") ? (
                                <div
                                  className=" btn btn-success"
                                  onClick={() =>
                                    navigate("subscriptions", {
                                      state: {
                                        chain_id: location?.state?.chain_id,
                                        university_id:
                                          location?.state?.university_id,
                                        grade_id: 1,
                                        course_id: item?.course_id,
                                        course_name: item?.course_name,
                                      },
                                    })
                                  }
                                >
                                  Subscriptions
                                </div>
                              ) : null}

                              <div
                                className=" btn btn-success"
                                onClick={() =>
                                  navigate("ChainsCoursesCards", {
                                    state: {
                                      chain_id: location?.state?.chain_id,
                                      university_id:
                                        location?.state?.university_id,
                                      grade_id: 1,
                                      course_id: item?.course_id,
                                      course_name: item?.course_name,
                                    },
                                  })
                                }
                              >
                                ChainsCoursesCards
                              </div>
                              {perms?.includes("*4_7") ||
                              perms?.startsWith("4_7") ? (
                                <div
                                  className="btn btn-success"
                                  onClick={() =>
                                    navigate("quizzes", {
                                      state: {
                                        chain_id: location?.state?.chain_id,
                                        university_id:
                                          location?.state?.university_id,
                                        grade_id: 1,
                                        course_id: item?.course_id,
                                        course_name: item?.course_name,
                                      },
                                    })
                                  }
                                >
                                  Quizzess
                                </div>
                              ) : null}
                              {perms?.includes("*4_8") ||
                              perms?.startsWith("4_8") ? (
                                <div
                                  onClick={() => {
                                    navigate("cournot", { data: item });
                                  }}
                                  className="btn text-light btn-warning"
                                >
                                  Notifications
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <p
                        className="middleCourseDescription"
                        onClick={() =>
                          perms?.includes("*4_1") || perms?.startsWith("4_1")
                            ? navigate("chapters", {
                                state: {
                                  university_id: 1,
                                  grade_id: 1,
                                  course_id: item?.course_id,
                                  chain_id: location?.state?.chain_id,
                                  course_name: item?.course_name,
                                },
                              })
                            : null
                        }
                      >
                        {item?.course_description}
                      </p>

                      <div className="bottomCourseCategory">
                        <span
                          onClick={() =>
                            perms?.includes("*4_1") || perms?.startsWith("4_1")
                              ? navigate("chapters", {
                                  state: {
                                    university_id:
                                      location?.state?.university_id,
                                    grade_id: 1,
                                    course_id: item?.course_id,
                                    chain_id: location?.state?.chain_id,
                                    course_name: item?.course_name,
                                  },
                                })
                              : null
                          }
                        >
                          {" "}
                          Course
                        </span>
                        {perms?.includes("*4_5") || perms?.startsWith("4_5") ? (
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              navigate("phonelist", {
                                state: {
                                  chain_id: location?.state?.chain_id,
                                  university_id: 1,
                                  grade_id: 1,
                                  course_id: item?.course_id,
                                  course_name: item?.course_name,
                                },
                              })
                            }
                          >
                            Auto Subuscription
                          </button>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <h4>No Courses</h4>
            )}
          </>
        }
      />
      <Modal
        headerTitle={"Add Course"}
        open={showAdd}
        toggle={setShowAdd}
        children={
          <form
            action="#"
            onSubmit={!loading ? addCourse : (e) => e.preventDefault()}
          >
            <div className="inputField">
              <label htmlFor="course_name">Course Name</label>
              <Input
                type={"text"}
                id={"course_name"}
                name={"course_name"}
                onChange={(e) => setCourseName(e.value)}
              />
            </div>
            <div className="inputField">
              <label htmlFor="course_description">Course description</label>
              <TextArea
                type={"text"}
                id={"course_description"}
                name={"course_description"}
                onChange={(e) => setCourseDescription(e.value)}
              />
            </div>
            <div className="inputField">
              <label htmlFor="course_Logo">Course Logo</label>
              <Input
                type={"file"}
                id={"course_Logo"}
                name={"course_Logo"}
                onChange={(e) => setCourseLogo(e.files[0])}
                showImage={true}
              />
            </div>

            <button className="btn btn-success">
              {loading ? <Loader /> : "Save"}
            </button>
          </form>
        }
      />
      <Modal
        headerTitle={"Edit Course"}
        open={editId}
        toggle={setEditId}
        children={
          <form
            action="#"
            onSubmit={!loading ? editCourse : (e) => e.preventDefault()}
          >
            <div className="inputField">
              <label htmlFor="course_name">Course Name</label>
              <Input
                type={"text"}
                id={"course_name"}
                name={"course_name"}
                defaultValue={editId?.course_name}
                onChange={(e) => setCourseName(e.value)}
              />
            </div>
            <div className="inputField">
              <label htmlFor="course_description">Course description</label>
              <TextArea
                type={"text"}
                id={"course_description"}
                name={"course_description"}
                defaultValue={editId?.course_description}
                onChange={(e) => setCourseDescription(e.value)}
              />
            </div>
            <div className="inputField">
              <label htmlFor="course_Logo">Course Logo</label>
              <Input
                type={"file"}
                id={"course_Logo"}
                name={"course_Logo"}
                onChange={(e) => setCourseLogo(e.files[0])}
                showImage={true}
              />
            </div>

            <button className="btn btn-success">
              {loading ? <Loader /> : "Save"}
            </button>
          </form>
        }
      />
      <Modal
        open={deleteId}
        toggle={setDeleteId}
        headerTitle={"Show / Hide Course"}
        children={
          <>
            <h5>
              {"Are You Sure To Show / Hide Course - " +
                deleteId?.course_name +
                " ?"}
            </h5>
            <div className="flex-box">
              <button
                className="btn btn-danger"
                onClick={() => {
                  handleDeleteCourse();
                }}
              >
                Yes
              </button>

              <button
                className="btn btn-primary"
                width={"fit-content"}
                onClick={() => {
                  setDeleteId(false);
                }}
              >
                No
              </button>
            </div>
          </>
        }
      />
    </div>
  );
};

export default ChainsCourses;
