import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import "./style.css";
import BurdCrumbs from "../../components/burdCrumbs";
import TableLayout from "../../components/table";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constants";
import { Input } from "../../components/form-elements";
import { Loader } from "rsuite";
import Modal from "../../components/modal";
import { addition } from "../../assets/svgIcons";
import { userData } from "../../data/fake_login_data";
import TextArea from "../../components/form-elements/textarea";
import { perms } from "../../data/side_nav_data";
import { exportToCSV } from "../../functions";
const Subjects = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [chainName, setSubjectName] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);
  const [chainLogo, setSubjectLogo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [materialData, setMaterialData] = useState({});
  const [material_id, set_material_id] = useState(null);
  const [loader, setLoader] = useState(false);
  const [hiddenId, setHiddenId] = useState(false);
  const [gradeName, setGradeName] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [editId, setEditId] = useState(false);
  const [opened, setOpened] = useState(null);
  const headers = [
    {
      label: "ID",
      dataIndex: "chain_id",
      search: true,
      sort: true,
    },
  
    perms?.includes("*1_1") || perms?.startsWith("1_1")
      ? {
          label: "Show Courses",
          type: "children",
          children: ({ headers, row }) => {
            return (
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    navigate("/chains/courses", {
                      state: {
                        chain_id: row?.chain_id,
                        chain_name: row?.chain_name,
                        university_id: 1,
                        grade_id: 1,
                      },
                    })
                  }
                >
                  Show
                </button>
              </div>
            );
          },
        }
      : {},
    {
      label: "Subject name",
      dataIndex: "chain_name",
      sort: true,
    },
    {
      label: "Subject Image",
      type: "children",
      children: ({ row }) => {
        return <img src={row?.photo} alt={row?.chain_name} />;
      },
    },
   
  ];
  window.addEventListener("click", (e) => {
    if (
      opened &&
      !e.target?.classList?.contains("actions-views") &&
      !e.target?.parentNode?.classList?.contains("open_close")
    ) {
      setOpened(null);
    }
  });
  const addSubject = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    const formData = new FormData();
    try {
      if (chainLogo) formData.append("image", chainLogo?.files[0]);
      const imageUpload = await axios.post(
        BASE_URL + "item_img_uploader.php",
        formData,
        {
          "Content-Type": "Content-Type: multipart/form-data",
        }
      );
      if (imageUpload?.data?.status == "success" && chainLogo) {
        const response = await axios.post(
          BASE_URL + "courses/chains/insert_chain.php",
          {
            chain_name: chainName?.value,
            university_id: 1,
            photo: imageUpload?.data?.message,
            admin_id: userData?.user_id,
            access_token: userData?.access_token,
            orderNumber: orderNumber?.value,
            grade_id: 1,
          }
        );

        if (response.data?.status == "success") {
          toast.success(response.data?.message);
          fetchData();
          setShowAdd(false);
          form.reset();
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(response.data?.message);
        }
      } else {
        setSubjectLogo(false);
        toast.error("Failed on Upload Image");
        setLoading(false);
      }
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const setArrangeNumber = async (material_id, material_number) => {
    setLoader(true);
    await axios
      .post(BASE_URL + "content/update_video_num.php", {
        chain_id: material_id,
        number: material_number,
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      })
      .then(async (response) => {
        toast.info(response?.data?.message);
        await fetchData();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };
  const fetchData = async () => {
    try {
      const response = await axios.post(
        BASE_URL + "courses/chains/select_chain.php",
        {
          university_id: 1,
          grade_id: 1,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );

      setData(response?.data?.message);
    } catch (err) {
      setData([]);
      toast.error(err?.message);
    }
  };

  const hideUniv = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        BASE_URL + "courses/chains/show_hide_chain.php",
        {
          grade_id: 1,
          chain_id: hiddenId?.chain_id,
          hidden: hiddenId?.status,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          university_id: 1,
        }
      );
      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setHiddenId(false);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const editSubject = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    const formData = new FormData();
    formData.append("image", chainLogo);
    try {
      const imageUpload = await axios.post(
        BASE_URL + "item_img_uploader.php",
        formData
      );
      if (
        imageUpload?.data?.status == "success" ||
        !chainLogo ||
        editId?.chain_photo
      ) {
        const response = await axios.post(
          BASE_URL + "courses/chains/update_chain.php",
          {
            chain_id: editId?.chain_id,
            photo:
              imageUpload?.data?.message &&
              imageUpload?.data?.message?.length &&
              chainLogo
                ? imageUpload?.data?.message
                : editId?.photo,
            chain_name: chainName ? chainName : editId?.chain_name,
            admin_id: userData?.user_id,
            access_token: userData?.access_token,
          }
        );
        if (response.data?.status == "success") {
          fetchData();
          setEditId(false);
          setSubjectLogo(null);
          setSubjectName(null);
          form.reset();
          setLoading(false);
          toast.success(response.data?.message);
        } else {
          setLoading(false);
          toast.error(response.data?.message);
        }
      } else {
        setSubjectLogo(false);
        toast.error("Failed on Upload Image");
        setLoading(false);
      }
      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // if (!location?.state?.university_id) {
  //   navigate(-1);
  // }

  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <h4 className="customBreadCrumbs">
              <span></span>
              {perms?.includes("*1_2") || perms?.startsWith("1_2") ? (
                <button
                  className="btn btn-primary"
                  onClick={() => setShowAdd(true)}
                >
                  <span>{addition}</span>
                  <span>Add Subject</span>
                </button>
              ) : null}
            </h4>
            {(perms?.includes("*17_4") || perms?.startsWith("17_4")) &&
            data?.length ? (
              <button
                className="btn btn-primary exportBtn"
                onClick={() => {
                  exportToCSV(
                    data,
                    location?.state?.grade_name + " - Subjects Data"
                  );
                }}
              >
                Export
              </button>
            ) : null}
            <TableLayout
              searchHeaderKet={searchHeaderKet}
              headers={headers}
              data={data}
            />
          </div>
        }
      />
      <Modal
        headerTitle={"Add Subject"}
        open={showAdd}
        toggle={setShowAdd}
        children={
          <form
            action="#"
            onSubmit={!loading ? addSubject : (e) => e.preventDefault()}
          >
            <div className="inputField">
              <label htmlFor="Subject name">Subject Name</label>
              <Input
                type={"text"}
                id={"Subject name"}
                name={"Subject name"}
                onChange={setSubjectName}
              />
            </div>
            <div className="inputField">
              <label htmlFor="Subject number">Subject Number</label>
              <Input
                type={"text"}
                id={"Subject number"}
                name={"Subject number"}
                onChange={setOrderNumber}
              />
            </div>
            <div className="inputField">
              <label htmlFor="Subject Logo">Subject Logo</label>
              <Input
                type={"file"}
                id={"Subject Logo"}
                name={"Subject Logo"}
                onChange={(e) => setSubjectLogo(e)}
                showImage={true}
              />
            </div>

            <button className="btn btn-success">
              {!loading ? "Save" : <Loader />}
            </button>
          </form>
        }
      />
      <Modal
        open={hiddenId}
        toggle={setHiddenId}
        headerTitle={"Show / Hide Subject"}
        children={
          <>
            <h5>
              Are You Sure To{" "}
              {hiddenId?.status == "true" ? "Active - " : "Inactive - "}
              {hiddenId?.chain_name} ?
            </h5>
            <div className="flex-box">
              <button
                className="btn btn-danger"
                onClick={() => {
                  return !loading ? hideUniv() : null;
                }}
              >
                {!loading ? "Yes" : <Loader />}
              </button>

              <button
                className="btn btn-primary"
                width={"fit-content"}
                onClick={() => {
                  setHiddenId(false);
                }}
              >
                No
              </button>
            </div>
          </>
        }
      />
      <Modal
        headerTitle={"Edit Subject"}
        open={editId}
        toggle={setEditId}
        children={
          <form
            action="#"
            onSubmit={!loading ? editSubject : (e) => e.preventDefault()}
          >
            <div className="inputField">
              <label htmlFor="Subject name">Subject Name</label>
              <Input
                type={"text"}
                id={"Subject name"}
                name={"Subject name"}
                defaultValue={editId?.chain_name}
                onChange={(e) => setSubjectName(e.value)}
              />
            </div>

            <div className="inputField">
              <label htmlFor="Subject Logo">Subject Logo</label>
              <Input
                type={"file"}
                id={"Subject Logo"}
                name={"Subject Logo"}
                onChange={(e) => setSubjectLogo(e.files[0])}
                showImage={true}
              />
            </div>

            <button className="btn btn-success">
              {loading ? <Loader /> : "Save"}
            </button>
          </form>
        }
      />
    </div>
  );
};

export default Subjects;
